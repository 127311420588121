import React from "react";

import Carousel from "react-bootstrap/Carousel";

import img1 from "../images/img1.jpg";
import img2 from "../images/img2.jpg";
import img3 from "../images/img3.jpg";

export default function Carousal() {
  return (
    <div>
      <div
        id="carouselExampleSlidesOnly"
        class="carousel slide px-2 mt-2"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={img1} class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img2} class="d-block w-100" alt="..." />
          </div>
          <div class="carousel-item">
            <img src={img3} class="d-block w-100" alt="..." />
          </div>
        </div>
      </div>
    </div>
  );
}
