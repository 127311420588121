import React from 'react'
import './Options.css'

import { Link } from 'react-router-dom'


export default function Options() {
    return (
        <div className='hide-extra-data'>


            <div className='row mx-2'>


                <a class="col-4 btn" onClick={() => { openNotification() }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/color/96/000000/news.png" alt="push-notifications" />
                        <br />
                        <b>
                            Latest Notices
                        </b>
                    </div>
                </a>


                <Link class="col-4 btn" to="/study">
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/color/96/man_reading_a_book.png" alt="push-notifications" />
                        <br />
                        <b>
                            Study
                        </b>
                    </div>
                </Link>


                <Link class="col-4 btn" to="/store">
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/external-justicon-lineal-color-justicon/64/external-cart-ecommerce-justicon-lineal-color-justicon.png" alt="push-notifications" />
                        <br />
                        <b>
                            Store
                        </b>
                    </div>
                </Link>



            </div>


            <div className='row mx-2'>

                <a class="col-4 btn" onClick={() => { showWebContent('https://webservices.ignou.ac.in/assignments') }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-assignment-gig-economy-flaticons-lineal-color-flat-icons-2.png" alt="push-notifications" />
                        <br />
                        <b>
                            Assignment
                        </b>
                    </div>
                </a>


                <a class="col-4 btn" onClick={() => { showWebContent('https://ignouadmission.samarth.edu.in/') }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-student-card-online-education-flaticons-lineal-color-flat-icons.png" alt="push-notifications" />
                        <br />
                        <b>
                            ID Card
                        </b>
                    </div>
                </a>


                <a class="col-4 btn" onClick={() => { openWhatsApp() }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/color/96/000000/whatsapp--v1.png" alt="push-notifications" />
                        <br />
                        <b>
                            WhatsApp
                        </b>
                    </div>
                </a>




            </div>

            <div className='row mx-2'>


                <a class="col-4 btn" onClick={() => { showWebContent("http://ignou.ac.in/ignou/aboutignou/division/sed/datesheet") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/fluency/96/overtime--v1.png" alt="push-notifications" />
                        <br />
                        <b>
                            DateSheet
                        </b>
                    </div>
                </a>


                <a class="col-4 btn" onClick={() => { showWebContent("https://ignouadmission.samarth.edu.in/") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/color/96/student-female.png" alt="push-notifications" />
                        <br />
                        <b>
                            Join IGNOU
                        </b>
                    </div>
                </a>


                <a class="col-4 btn" onClick={() => { showWebContent("http://ignou.ac.in/ignou/aboutignou/regional/website") }}>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/external-flaticons-flat-flat-icons/64/external-group-market-research-flaticons-flat-flat-icons-4.png" alt="push-notifications" />
                        <br />
                        <b>
                            Regional Center
                        </b>
                    </div>
                </a>



            </div>



            <div className='row mx-2'>

                <Link class="col-4 btn" to='/more'>
                    <div>
                        <img className='' width="56" height="56" src="https://img.icons8.com/plasticine/100/folder-invoices.png" alt="push-notifications" />
                        <br />
                        <b>
                            More Options
                        </b>
                    </div>
                </Link>



            </div>



        </div>
    )
}


function showWebContent(url) {
    if (url != null) {
        window.Android.showWebContent(url)
    }
}

function openNotification() {
    window.Android.openNotification();
}

function openWhatsApp() {
    window.Android.openWhatsApp();
}