import logo from "./logo.svg";
import "./App.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Study from "./pages/Study";
import Store from "./pages/Store";
import More from "./pages/More";

import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Disclaimer from "./pages/Disclaimer";
import PageNotFound from "./pages/PageNotFound";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/study" element={<Study />}></Route>
          <Route path="/store" element={<Store />}></Route>
          <Route path="/more" element={<More />}></Route>
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="/disclaimer" element={<Disclaimer />}></Route>
          <Route path="/contact-us" element={<ContactUs />}></Route>

          <Route path="*" element={<PageNotFound />}></Route>
        </Routes>
      </BrowserRouter>

      {/* Promotion Banner */}
      <div id="banner">
        <img
          id="banner-img"
          src="https://ignouplus.com/wp-content/uploads/2023/12/online-solved-assignments.png"
        />
        <img
          id="close-btn"
          onClick={() => {
            closeBtn();
          }}
          width={40}
          height={40}
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqUlEQVR4nO2a22pTURCGlxXSCrZiLrW+gJp30NTDVdP6PlKx3onHC7Vo8MInqIiR6nMoReqV1ptGb7WQ8smwVyDE7J19WofofBAo2ZPOnn+vw8yabYyiKIqiKIqieAFYAFrAFWDNfuTvi3LN/GsA88ANYAvYBY5I58jaPAPWgYaZVYBzwEPgB+WR3z4Als2sAJwCHgOH1MehFWLJxAxwHfiOO/aBqyY2gGPA5pT5XRfi45b4NDEAHAde4p+u+I7hyXcJx6ugI4Fk2IdmI1TwbWAQOnq7JlzzHfyS49W+KN+ARZ8CPCI+7vsKfrnmJKcufgNnY336n4GdnGlx39rKb+IaBSSFTdHc/vZwqwKaQC/DVq6dtrZzJXaZvtMCiqSqK4I8xbkJIr6ZYCvfzY/Zigh7BX12XAqwVfBmdlL+z7gIfwU/Yvu+oM+nLgXYLXgzMl2aU0TICl6mzM+CPj+5Cv5EyWKnlxFgI23OWoGy1os0Bmn+qgrQojypTzkj+EnrRF4uuBBghWq8y3PmZ0fF64q+LrkQYK3iTU0Voabg3ewE1CNAL4cA27EKsOJxCmzHOAVaHhfBRoyL4ILnbVCuvY1mGyyZCPWHub3HROijcQVJxyb2VPiJSwHWZ6AYWnUpQKNEObw5FCFnOdwcCf5OQV8HzvuJJP2+ouzZoZxnPovNB+BLCT/3nAY/ciQmx0//55GYYBuVsXHXeD4W3ycevgInvQkgAJcjaoyE6RiTdGlDczNI8CPN0RcBg39uImmPd0MEH7w9PjYSNjy9IDEIOuyzsK+7SaPS5WrfNjEDLEpGVnOy9Ev2ee9bXRUkK5NenS2Jy3JgxTxjZhWSAqojZao0LabkDgNrI7arM/2iZBq2HD5v3zDp2E/bfufmJEdRFEVRFEVRzDh/AP99HATCU2zcAAAAAElFTkSuQmCC"
        />
        <button
          id="action-btn"
          className="btn border-warning bg-warning text-white w-25 p-2"
          onClick={() => {
            actionBtn();
          }}
        >
          <strong>Get Now</strong>
        </button>
      </div>
    </div>
  );
}

export default App;

function promoFun() {
  if (getCookie("banner") == null) {
    setTimeout(showBanner, 2000);
  }
}

function showBanner() {
  let banner = document.getElementById("banner");
  banner.style.display = "block";
}

function closeBtn() {
  let banner = document.getElementById("banner");
  banner.style.display = "none";
  setCookie("banner", "seen");
}

function actionBtn() {
  try {
    window.Android.showWebContent("https://chatbot.ignouplus.com/");
    let banner = document.getElementById("banner");
    banner.style.display = "none";
    setCookie("banner", "seen");
  } catch (e) {}
}

function getCookie(name) {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName === name) {
      return cookieValue;
    }
  }
  return null;
}

//Expiry date for 4 hours
function setCookie(name, value) {
  try {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + 4 * 60 * 60 * 1000);
    const expires = "expires=" + expirationDate.toUTCString();
    document.cookie =
      name + "=" + value + "; " + expires + ";SameSite=None; Secure; path=/";
  } catch (e) {}
}
