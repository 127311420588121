import React from 'react'
import BottomBar from '../component/BottomBar'

import Carousel from '../page-store/Carousal'
import Options from '../page-store/Options'

import commingSoon from '../images/store-bg.avif'


export default function Store() {
  return (
    <div>

        {/* <Carousel /> */}

        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/FBYe_WQE2yU" frameborder="0" allowfullscreen></iframe> */}
        

        <Options />




        <BottomBar selectStore="true" />

    </div>
  )
}
